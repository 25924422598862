import type { ComponentType, FC, PropsWithChildren } from 'react';
import clsx from 'clsx';

export type SystemProviderProps = {
  as?: ComponentType<{ className?: string } & PropsWithChildren>;
} & PropsWithChildren;

export const SystemProvider: FC<SystemProviderProps> = ({
  as: As = 'div',
  children,
}) => {
  const classes = clsx('font-poppins bg-background text-lead');
  return <As className={classes}>{children}</As>;
};

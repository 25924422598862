import { captureRemixErrorBoundaryError, withSentry } from '@sentry/remix';
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
} from '@remix-run/react';
import './tailwind.css';
import '@fontsource/poppins/latin.css';
import { SystemProvider } from '@ck/ui';
import { useRouteError } from '@remix-run/react';
import { GeneralErrorBoundry } from './GeneralErrorBoundry';

function App() {
  return (
    <html lang="no" dir="ltr">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body>
        <SystemProvider>
          <Outlet />
        </SystemProvider>
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

export default withSentry(App);

export function ErrorBoundary() {
  const error = useRouteError();
  captureRemixErrorBoundaryError(error);
  return (
    <html lang="no" dir="ltr">
      <head>
        <title>Oops!</title>
        <Meta />
        <Links />
      </head>
      <body>
        <div className="w-full ml-32">
          <GeneralErrorBoundry error={error} />
        </div>
        <Scripts />
      </body>
    </html>
  );
}
